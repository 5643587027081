import React, { useEffect, useState, useContext } from "react";
import { LanguageContext } from "globant";
import LiveContent from "../components/liveContent/liveContent";
import Navbar from "../components/navbar/navbar";
import whitelogo from "../images/livestream/logoGlobantWhite.svg";
import { Helmet } from "react-helmet";
import favIcon from "../images/general/FavIcon.png";

export default function Live({ pageContext }) {

  const [data, setListData] = useState([]);
  const [language, setLanguage] = useContext(LanguageContext); // eslint-disable-line

  useEffect(() => {

    let time_stamp = Math.floor(Date.now() / 1000);

    const url = process.env.GATSBY_API_URL + "?timestamp=" + time_stamp;

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setListData(json);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();

    if (pageContext.language) {
      setLanguage(pageContext.language);
    }
  }, [pageContext, setLanguage]);

  return (
    <LanguageContext.Consumer>
      {language => (
        <>
          <Helmet defer={false}>
            <title>Tech N' Fest 2022 | By Globant</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="icon" type="image/png" sizes="16x16" href={favIcon} />
            <link rel="icon" type="image/png" sizes="32x32" href={favIcon} />
            <link rel="canonical" href="https://technfest.globant.com/" />
            <meta name="robots" content="index, follow"/>
            <meta name="description" content="Únete a nuestro Tech N' Fest 2022. Comparte en el primer bar para Techies, escucha a los grandes de la tecnología y expande tus pasiones." />
            <meta property="og:locale" content="en_ES" />
            <meta property="og:type" content="website" data-react-helmet="true"/>
            <meta property="og:url" content="https://technfest.globant.com/" data-react-helmet="true"/>
            <meta property="og:title" content=" Tech N’ Fest 2022 | By Globant" data-react-helmet="true"/>
            <meta property="og:description" content=" Únete a nuestro Tech N' Fest 2022. Comparte en el primer bar para Techies, escucha a los grandes de la tecnología y expande tus pasiones." data-react-helmet="true"/>
            <meta property="og:site_name" content="Tech N Fest" data-react-helmet="true"/>
            <meta property="og:image" content="https://technfest.globant.com/Facebook-tech-n-fest.jpg" data-react-helmet="true"/>
            <meta property="og:image:secure_url" content="https://technfest.globant.com/Facebook-tech-n-fest.jpg" data-react-helmet="true"/>
            <meta name="twitter:image" content="https://technfest.globant.com/Twitter-tech-n-fest.jpg" data-react-helmet="true"/>
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:description" content="Únete a nuestro Tech N' Fest 2022. Comparte en el primer bar para Techies, escucha a los grandes de la tecnología y expande tus pasiones." />
            <meta name="twitter:title" content="Tech N’ Fest 2022 | By Globant" />
            <meta name="twitter:site" content=" https://technfest.globant.com/" />
            <meta name="twitter:creator" content="@Globant" />
            <meta name="google-site-verification" content="Uvfh2FPA1L99aAHBQgqr8SNl4Wp_0OFkcdzaZTXmBfI" />
            <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/4f9456e1a528e8296a5ddf80/script.js"></script>
          </Helmet>
          <div className="event">
            <img data-src={whitelogo} alt="logo" className="lazyload event__uplogo" ></img>
            {( data.length !== 0 ? <Navbar data={data} currentPage="/live"></Navbar>: null)}
            {( data.length !== 0 ? <LiveContent data={data}/>: null)}
          </div>
        </>
      )}
    </LanguageContext.Consumer>
  )
};
import React, { useState } from "react";
import { useHrefFormat, useTranslateField } from "globant";
import LanguageMenu from "../languageMenu/languageMenu";

// Images
import hamburguer from '../../images/navbar/hamburguer.svg';
import close from '../../images/general/close.svg';

// Files
import "./navbar.scss";

const Navbar = ({ data, currentPage }) => {
  const t = useTranslateField;
  const hrefFormat = useHrefFormat;

  const mainMenu = t({ field: data.field_main_menu })
  const [isExpanded, setIsExpanded] = useState(false)

  const handlerExpand = () => {
    setIsExpanded(isExpanded => !isExpanded)
  }

  return (
    <nav className="nav">
      {!isExpanded ?
        <button onClick={handlerExpand} className="nav__menu">
          <img src={hamburguer} alt="Globant" />
        </button> : null
      }

      {isExpanded ?
        <button onClick={handlerExpand} className="nav__close">
          <img src={close} alt="Globant" />
        </button> : null
      }

      <ul className={isExpanded ? "is-expanded" : null}>
        {mainMenu.map((menuItem, menuIndex) => (
          <li key={menuIndex}>
            <a href={hrefFormat({uri: menuItem.uri})} onClick={isExpanded ? handlerExpand : null}>{ menuItem.title }</a>
          </li>
        ))}
        <li>
          <LanguageMenu currentPage={currentPage}/>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar;
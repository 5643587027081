import React from "react";
import { useTranslateField } from "globant";

// Files
import "./liveContent.scss";
import whitelogo from '../../images/livestream/logoGlobantWhite.svg'
import textLogo from "../../images/livestream/txtGlobant.svg"

const LiveContent = ({ data }) => {

  let iframeContent = useTranslateField({ field: data.field_streaming });

  if (data.field_streaming) {
    let parser = new DOMParser();
    let parserIframe = parser.parseFromString(iframeContent, "text/html");

    if (parserIframe.querySelector("iframe") !== null) {
      let iframe = parserIframe.querySelector("iframe");
      let originalIframe = parserIframe.querySelector("iframe").outerHTML;

      iframe.removeAttribute('width');
      iframe.removeAttribute('style');
      iframe.removeAttribute('height');

      iframeContent = iframeContent.replace(originalIframe, iframe.outerHTML);
    }
  }

  return (
    <section className="event__content">
      <div className="event__content--iframe" dangerouslySetInnerHTML={{__html: iframeContent }}></div>

      <div className="event__content--footer">
        <div>
          <img data-src={whitelogo} alt="logo" className="lazyload" ></img>
          <img data-src={textLogo} alt="logo" className="lazyload" ></img>
        </div>
      </div>
    </section>
  )
}

export default LiveContent;

import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import { LanguageContext, useTranslateText } from "globant";

import "./languageMenu.scss";

const LanguageMenu = ({ currentPage }) => {
  const [language, setLanguage] = useContext(LanguageContext) // eslint-disable-line
  const [langMenuOpen, setLangMenuOpen] = useState(false)
  const btnText = (language === 'en') ? 'english' : 'spanish'
  const classTitle = langMenuOpen ? 'opened' : 'closed'

  const tT = useTranslateText
  const toggleMenu = () => {
    setLangMenuOpen(langMenuOpen => !langMenuOpen)
  }

  let currentQueryArgs = ''

  if (typeof window?.location.search !== 'undefined') {
    currentQueryArgs = window?.location.search
  }

  if (currentQueryArgs !== '') {
    currentPage = currentPage + currentQueryArgs;
  }

  return (
    <div className='lang-menu'>
      <button
        type="button"
        className="lang-menu--header"
        onClick={toggleMenu}
      >
        <div className={'lang-menu--header--title ' + classTitle}>{ tT(btnText) }</div>
      </button>
      {langMenuOpen && (
        <div
          role="list"
          className="lang-menu--list"
        >
          <Link to={'/en' + currentPage } className='lang-menu--list--item'>{ tT('english') }</Link>
          <Link to={ currentPage } className='lang-menu--list--item'>{ tT('spanish') }</Link>
        </div>
      )}
    </div>
  )
}

export default LanguageMenu